.social-icons {
    font-size: 2rem;
    color: #f08e80;
    margin: 1rem;
    cursor: pointer;
}

.social-icons:hover {
    color: #333333;
}

@media only screen and (max-width: 760px) {
    .social-icons {
        font-size: 1.25rem;
        margin: .75rem;
        cursor: pointer;
    }
  }

  .green-text{
    color: #222A36;
  }
